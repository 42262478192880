<template>
  <div class="wap-message">
    <headerBar
      :title="$t('xiao-xi-lie-biao')"
      v-if="pageType === 'user'"
      @back="back"
    ></headerBar>
    <div class="title" v-else>{{ $t('common.tab4') }}</div>
    <div class="message-list">
      <div
        class="message"
        v-for="(item, i) in messageList"
        :key="i"
        @click="toDetail(item)"
      >
        <div class="avatar">
          <div class="user-header header-2"></div>
        </div>
        <div class="info">
          <div class="name">{{ showName(item) }}</div>
          <div class="flex-center">
            <div class="content">{{ item.Content }}</div>
            <div class="date">{{ resetDate(item.LastMessageTime) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { conversationList } from '@/api/shop'
import { getUserId } from '@/utils/auth'
import headerBar from '@/components/header'
import moment from 'moment'
export default {
  components: {
    headerBar
  },
  data() {
    return {
      messageList: [],
      pageType: ''
    }
  },
  mounted() {
    this.init()
    this.pageType = this.$route.name === 'wapUserMessage' ? 'user' : ''
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      conversationList({
        current: 1,
        pageSize: 30
      }).then((res) => {
        this.messageList = res.data.Items
      })
    },
    resetDate(date) {
      let today = moment().format('YYYY-MM-DD')
      if (date.indexOf(today) === 0) {
        return date.substring(11, 16)
      } else {
        return date.substring(0, 16)
      }
    },
    toDetail(data) {
      this.$router.push({
        name: 'wapChat',
        query: {
          uid: data.SenderID,
          name: this.showName(data)
        }
      })
    },
    showName(item) {
      let uid = getUserId()
      if (uid != item.ReceiverID) {
        if (item.ReceiverShop.ID > 0) {
          return item.ReceiverShop.Name
        } else {
          return item.ReceiveUser.Email || item.ReceiveUser.Phone
        }
      } else {
        if (item.SenderShop.ID > 0) {
          return item.SenderShop.Name
        } else {
          return item.SendUser.Email || item.SendUser.Phone
        }
      }
    }
  }
}
</script>